import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/countNumber.js'
import gsap from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

function numScroll() {
  $(".number .num").each(function(){
    let num = $(this).attr('data-num')
    $(this).rollNum({
      deVal: num
    });
  })
}

$(document).ready(function(){
  //banner轮播
  var bannerswiper = new Swiper(".section1 .mySwiper", {
    // loop: true,
    speed: 800,
    watchSlidesProgress: true,
    // effect : 'fade',
    // autoplay: {
    //   delay: 6000,
    //   stopOnLastSlide: false,
    //   disableOnInteraction: false,
    //   waitForTransition: false,
    // },
    pagination: {// 分页
      el: ".section1 .swiper-pagination",
      clickable: true,
    },
  })

  //文字滚动渐变
  let paragraphs = [...document.querySelectorAll('.section2 .ani-t')];

  paragraphs.forEach(paragraph => {
    let htmlString = '';
    let pArray = paragraph.textContent.split('');
    for(let i = 0; i< pArray.length; i++){
      htmlString += `<span class="char">${pArray[i]}</span>`;
    }
    paragraph.innerHTML = htmlString;
  })

  $(".section2 .sec2-text").each(function (index) {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: $(this),
        start: "top 80%",
        end: "top 25%",
        scrub: 0.5
      }
    });
    tl.from($(this).find(".char"), { opacity: 0.15, duration: 0.3, ease: "power1.out", stagger: { each: 0.4 } });
  });
  
  // map
  // $('.section2 .sec2-cont .left .location-list li').eq(0).addClass('active')
  $('.section2 .sec2-cont .left .location-tab li').eq(0).addClass('active')
  // $('.section2 .sec2-cont .left .location-list li').hover(function(){
  //   let index = $(this).index()
  //   $('.section2 .sec2-cont .left .location-list li').eq(index).addClass('active').siblings().removeClass('active')
  //   $('.section2 .sec2-cont .left .location-tab li').eq(index).addClass('active').siblings().removeClass('active')
  // })
  // $('.section2 .sec2-cont .left .location-tab li').hover(function(){
  //   let index = $(this).index()
  //   $('.section2 .sec2-cont .left .location-list li').eq(index).addClass('active').siblings().removeClass('active')
  //   $('.section2 .sec2-cont .left .location-tab li').eq(index).addClass('active').siblings().removeClass('active')
  // })

  var mouseover_tid = [];
  var mouseout_tid = [];
  $(".section3 ul li").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[ index ] = setTimeout(function () {
          $(_self).addClass('active').siblings().addClass('other');
        }, 101);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[ index ] = setTimeout(function () {
          $(_self).removeClass('active').siblings().removeClass('other');
        }, 101);
      });
  });

  // 新闻轮播
  var newswiper = new Swiper(".section4 .sec4-swiper .mySwiper", {
    speed: 600,
    effect : 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      waitForTransition: false,
    },
    pagination: {// 分页
      el: ".section4 .sec4-swiper .swiper-pagination",
      clickable: true,
    },
    navigation: {//按钮
      nextEl: ".section4 .sec4-swiper .swiper-button-next",
      prevEl: ".section4 .sec4-swiper .swiper-button-prev",
    },
    on:{
      init: function(swiper){
        $('.section4 .sec4-swiper .img a').eq(this.realIndex).fadeIn(300)
      },
      slideChangeTransitionStart: function(){
        $('.section4 .sec4-swiper .img a').eq(this.realIndex).fadeIn(300).siblings().fadeOut(300)
      },
    }
  })

  // 社会责任
  var socialswiper = new Swiper(".section5 .mySwiper", {
    speed: 600,
    effect : 'fade',
    allowTouchMove: false,
    on:{
      init: function(swiper){
        $('.section5 .sec5-tab>div').eq(this.realIndex).addClass('active')
      },
      slideChangeTransitionStart: function(){
        $('.section5 .sec5-tab>div').eq(this.realIndex).addClass('active').siblings().removeClass('active')
      },
    }
  })
  $('.section5 .sec5-tab>div').hover(function(){
    let index = $(this).index()
    socialswiper.slideTo(index)
  })

})

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度
  var rollNum = $('.section2 .sec2-cont .right .num-cont').offset().top - viewH*4/5
  var bgTop = $('.section2 .sec2-cont').offset().top - viewH/2

  if(scroH > rollNum){
    numScroll()
  }

  if(scroH < viewH/2){
    $(".number .num").each(function(){
      let num = $(this).attr('data-num')
      $(this).html(num);
    })
    $('.section2 .sec2-cont .bg').removeClass('active')
  }
  if(scroH > bgTop){
    $('.section2 .sec2-cont .bg').addClass('active')
  }
});